<template>
  <list-template
      :loading="loading"
      :total="total"
      :table-data="tableData"
      :current-page="page"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onAdd="$router.push('./add')"
      @onSearch="onSearch"
      @onChangePage="onPageChange"
      @onHandle="onHandle"
  />

</template>
<script>
import listTemplate from "@/components/pages/List"
import {mapState} from "vuex";

export default {
  _config: {route: {path: "list", meta: {title: "列表", keepAlive: true}}},
  components: {listTemplate},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop:"name",
          placeholder:"搜索收费方案名称"
        }
      ],
      tableConfig: [
          // 收费方案名称,业务类型,总金额,创建人,创建时间,操作
        {
          prop:"name",
          label:"收费方案名称"
        },
        {
          prop:"type",
          label:"业务类型"
        },
        {
          prop:"total_amount",
          label:"总金额"
        },
        {
          prop:"creator",
          label:"创建人"
        },
        {
          prop:"created_at",
          label:"创建时间"
        },
        {
          label:"操作",
          handle:true,
          width:"80"
       }
      ],
      tableData: [],
      total: 0,
      pageCount: 20
    }
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData();
  },
  activated() {
    this.getData();
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {
        ...this.search,
        page:this.page
      };
      this.$_register.get("api/finance/fee-plan/list",{
        params
      }).then(res=>{
        let data = res.data.data;
        this.tableData = data.list;
        this.total = data.page.total
      }).finally(()=>this.loading = false)
    },
    onHandle(row, p) {
      // list button handle ...
      this.$router.push(`./edit?id=${row.id}`);
    },
    // 当前页改变触发
    onPageChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },    // 搜索按钮
    onSearch(val) {
      this.search = val;
      this.$nextTick(this.getData);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
